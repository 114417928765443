import React, { useRef } from 'react';
import Seo from '../components/SEO';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ValueBloc from '../components/ValueBloc';
import ReactPlayer from 'react-player/youtube';
import Future from '../components/Future';

import useSound from 'use-sound';
import Tape from '../sounds/tape.mp3';
import Sparkle from '../sounds/sparkle.mp3';
import Cutting from '../sounds/cutting.mp3';
import Cut from '../sounds/cut.mp3';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function SustainabilityPage() {
  const myRefMaterial = useRef(null);
  const myRefPackaging = useRef(null);
  const myRefPlanting = useRef(null);
  const myRefVideo = useRef(null);

  const image = useStaticQuery(graphql`
    query {
      organic: file(relativePath: { eq: "organic.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      packaging: file(relativePath: { eq: "packaging.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      plant: file(relativePath: { eq: "tree.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
  `);
  const [tape] = useSound(Tape, { volume: 0.25 });
  const [sparkle] = useSound(Sparkle, { volume: 0.05 });
  const [cutting] = useSound(Cutting, { volume: 0.4 });
  const [cut] = useSound(Cut, { volume: 0.4 });

  return (
    <section
      className="mt-16 text-primary md:mt-20 lg:mt-12"
    >
      <Seo pageTitle="sustainability" />
      <div className="flex flex-col justify-center flex-grow -mb-px border lg:flex-row border-primary ">
        <div
          style={{ height: '80vh' }}
          className="flex flex-col justify-center p-8 -mr-px lg:w-2/4 md:w-full items-left md:p-16 "
        >
          <p className="font-serif text-5xl font-extrabold leading-tight md:text-6xl">
            sustain-
            <br />
            ability
          </p>
          <br />
          <p className="max-w-lg text-base opacity-80">
            the mission is to create a conscious clothing company with the
            betterment of the planet in mind. <br />
            <br />
            here are some of the ways we are doing so.
          </p>
        </div>
        <div className="-ml-px lg:w-2/4 md:w-full ">
          <div className="flex flex-col justify-between w-full h-full -mr-px">
            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary lg:border-t-0 border-t-1 hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefMaterial);
                cutting();
              }}
            >
              materials
            </button>

            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefPackaging);
                tape();
              }}
            >
              packaging
            </button>

            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefPlanting);
                sparkle();
              }}
            >
              planting
            </button>
            <button
              className="flex flex-col items-center justify-center w-full h-32 -mb-px text-2xl font-bold border border-r-0 lg:h-full border-primary hover:bg-primary hover:text-white"
              onClick={() => {
                scrollToRef(myRefVideo);
                cut();
              }}
            >
              video
            </button>
          </div>
        </div>
      </div>
      <section
        ref={myRefMaterial}
        className="flex flex-row items-center justify-center w-full border border-primary"
        id="contact"
      >
        <div className="flex flex-col w-full sm:flex-row">
          <div className="flex flex-col justify-center p-16 sm:w-full md:w-1/2 xs:border-0 md:border-r border-primary">
            <p className="mt-4 font-serif text-3xl font-extrabold">
              organic materials
            </p>
            <br />
            <p className="max-w-lg text-base opacity-80">
              we strive to cultivate a sustainable process, from farm to factory
              and construction to consumption. Ensuring we’re reducing our
              impact on the planet at every possible turn. All our items are
              made from sustainably sourced materials.
            </p>
          </div>
          <div className="sm:w-full md:w-1/2">
            <GatsbyImage
              image={image.organic.childImageSharp.gatsbyImageData}
              className="heroGatsbyImage"
              alt="organic tee"
            />
          </div>
        </div>
      </section>
      <section
        ref={myRefPackaging}
        className="flex flex-col items-center justify-center w-full -mt-px border border-primary"
        id="deliverey"
      >
        <div className="flex flex-col w-full sm:flex-row">
          <div className="sm:w-full md:w-1/2 border-primary xs:border-0 md:border-r">
            <GatsbyImage
              image={image.packaging.childImageSharp.gatsbyImageData}
              className="h-full heroGatsbyImage"
              alt="organic tee"
            />
          </div>
          <div className="flex flex-col justify-center p-16 sm:w-full md:w-1/2 border-primary">
            <p className="mt-4 font-serif text-3xl font-extrabold">
              eco packaging
            </p>
            <br />
            <p className="max-w-lg text-base opacity-80">
              our packaging is made from recycled plastics and is recyclable,
              reducing our environmental footprint.
            </p>
          </div>
        </div>
      </section>
      <section
        ref={myRefPlanting}
        className="flex flex-col items-center justify-center w-full -mt-px border border-primary"
        id="sizing"
      >
        <div className="flex flex-col w-full sm:flex-row">
          <div className="flex flex-col justify-center p-16 sm:w-full md:w-1/2 xs:border-0 md:border-r border-primary">
            <p className="mt-4 font-serif text-3xl font-extrabold">
              plant trees
            </p>
            <br />
            <p className="max-w-lg text-base opacity-80">
              buy one, and we’ll plant one. With each purchase you will help us
              plant a tree. <br />
              <br />
              {/* <a href="https://teamtrees.org" className="underline">
                https://teamtrees.org
              </a> */}
            </p>
          </div>
          <div className="sm:w-full md:w-1/2">
            <GatsbyImage
              image={image.plant.childImageSharp.gatsbyImageData}
              className="h-full heroGatsbyImage"
              alt="organic tee"
            />
          </div>
        </div>
      </section>

      <section className="-mt-px border border-primary" ref={myRefVideo}>
        <h1 className="py-8 text-4xl font-bold text-center border-b border-primary">
          a lil video we made
        </h1>
        <ValueBloc />
        <div className="-mt-px border player-wrapper border-primary">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/cn5TU51lEzg"
            width="100%"
            height="100%"
          />
        </div>
      </section>
      <section className="mb-16 -mt-px border border-primary">
        <Future />
      </section>
    </section>
  );
}

export default SustainabilityPage;
